import * as _interopRequireDefault3 from "@babel/runtime/helpers/interopRequireDefault";

var _interopRequireDefault2 = "default" in _interopRequireDefault3 ? _interopRequireDefault3.default : _interopRequireDefault3;

import * as _interopRequireWildcard3 from "@babel/runtime/helpers/interopRequireWildcard";

var _interopRequireWildcard2 = "default" in _interopRequireWildcard3 ? _interopRequireWildcard3.default : _interopRequireWildcard3;

import * as _ramda2 from "ramda";

var _ramda = "default" in _ramda2 ? _ramda2.default : _ramda2;

import * as _hyphen3 from "hyphen";

var _hyphen2 = "default" in _hyphen3 ? _hyphen3.default : _hyphen3;

import * as _enUs3 from "hyphen/patterns/en-us";

var _enUs2 = "default" in _enUs3 ? _enUs3.default : _enUs3;

var exports = {};
var _interopRequireDefault = _interopRequireDefault2;
var _interopRequireWildcard = _interopRequireWildcard2;
exports.__esModule = true;
exports.default = void 0;

var R = _interopRequireWildcard(_ramda);

var _hyphen = _interopRequireDefault(_hyphen2);

var _enUs = _interopRequireDefault(_enUs2);

var SOFT_HYPHEN = "\xAD";
var hyphenator = (0, _hyphen.default)(_enUs.default);
var splitHyphen = R.split(SOFT_HYPHEN);
var cache = {};
var getParts = R.ifElse(R.contains(SOFT_HYPHEN), splitHyphen, R.o(splitHyphen, hyphenator));

var wordHyphenation = function wordHyphenation(options, word) {
  var cacheKey = "_" + word;
  if (R.isNil(word)) return [];
  if (cache[cacheKey]) return cache[cacheKey];
  cache[cacheKey] = getParts(word);
  return cache[cacheKey];
};

var _default = R.curryN(2, wordHyphenation);

exports.default = _default;
export default exports;
export const __esModule = exports.__esModule;